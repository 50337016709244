.create-pass-container{
    width: 100vw;
    height: 100vh;
}

.create-pass-content{
    width: 300px;
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    background-color:white;
    padding: 40px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.create-pass-content h1{
    font-family:"custom-body";
    font-size: 15px;
    text-align: center;
}

.create-button{
    width: 100%;
    background-color: transparent;
    border: 1px solid black;
    height: 34px;
    transition: all .4s ease-in;
}

.create-button:hover{
    background-color: black;
    color:white;
}

.date-input-form{
    width: 98%;
    border: 1px solid black;
    height: 34px;
    margin-bottom: 10px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: black;
    border-radius: 0;
    background-color: transparent;
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid black;
}

.margin-top-create{
    margin-top: 10px;
}

.create-form{
    background-color:transparent;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
}


@media only screen and (max-width: 767px) {
	.create-pass-content{
        width: 250px;
	}
  }