.dashboard-container{
    width: 100vw;
    height: 100vh;
}

.dashboard-content{
    width: 300px;
    height: auto;
    position: relative;
    left:50%;
    transform: translate(-50%, -50%);
    top: 50%;
    background-color: white;
    padding: 40px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.welcome-content{
    margin-bottom: 20px;
    text-align: center;
    /* font-family:"custom-body"; */
}

.welcome-content h1{
    font-family:"custom-body";
}

.welcome-content p{
    font-family: "custom-body";
    /* text-align: left; */
}

.qr-code{
    position: relative;
    left: 50%;
    transform:translateX(-50%);
    width: 250px;
}

.dashboard-button{
    width: 300px;
    border: 1px solid black;
    background-color: white;
    height: 30px;
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
}

.default-card{
    text-align:center;
    
}

.default-card p{
    font-family: "custom-body";

}

.apple-pass{
    /* border: none; */
    background-color: transparent;
    border:none;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: auto;
    height: 40px;
}

.apple-pass-image{
    position: relative;
    left: 33%;
    top: 10px;
    /* transform:translate(-50%); */
}

@media only screen and (max-width: 767px) {
	.dashboard-content{
        width: 250px;
	}
  }

  .loader {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .loader:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: #000;
    animation: loader 1s ease-in-out infinite;
  }
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  