
.password-container{
    width: 100vw;
    height: 100vh;
}


.password-content-container{
    position: relative;
    top: 50%;
    left:50%;
    transform:translate(-50%, -50%);
    width: 85%;
}

.password-content-container h1{
    font-family: "custom-body-light";
    font-size: 25px;
    text-transform: none;
    text-align: left;
    margin-bottom: 25px;
}

.password-content{
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-content input{
    background-color:transparent;
    font-family: "custom-body";
}

.password-content button{
    margin-left: 10px;
    /* border-radius: 0px; */
    width: 150px;
}

@media screen and (max-width: 768px) {

    .password-content-container h1{
        font-size: 20px;
    }
  }