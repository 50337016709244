.register-container{
    width: 100vw;
    height: calc(100vh - 34px);
}

.register-content{
    width: 350px;
    position: relative;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%);
}

.login-button-google{
    width: 100%;
    background-color:white;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 15px;
}

#left-align{
    text-align: left;
}

.register-prompt{
    font-size: 12px !important;
    text-transform: uppercase;
}

.margin-bottom{
    margin-bottom: 15px;
}

.input-form-snippet{
    position: inline-block;
    width: 25%;
    height: 34px;
    font-family:"custom-body";
    margin-right: 25px;
    margin-top: 20px;
    font-size: 20px;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
}

.margin-top{
    margin-top: 15px;
}

#phone-number{
    font-size: 24px;
    border-top: none;
    border-left: none;
    border-right:none;
    margin-top: 20px;
    font-family:"custom-body";
    -webkit-appearance: none;
    background-color: transparent;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    padding: 0;
}

.grecaptcha-badge{
    position: relative !important;
    bottom: 0px !important;
    right: 0px !important;
    margin-top: 10px !important;
}


.input-form-snippet:nth-child(3){
    margin-right: 0px;
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .input-form-snippet{
        margin-right: 1px;
    }

    .register-content{
        width: 85%;
    }

}

/* .login-container{
    width: 100vw;
    height: 100vh;
}

.login-content{
    width: 300px;
    position: relative;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%);
} */

/* .login-button-google{
    width: 100%;
    background-color:white;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 15px;
} */

.register-prompt{
    font-size: 12px !important;
    text-transform: uppercase;
    /* font-family:'custom';
    text-transform: uppercase;
    letter-spacing: 1px; */
    /* text-decoration: none !important; */
}

.header-text{
    text-align: center;
    margin-bottom: 10px;
    font-family:'custom-body';
    font-size: 20px;
}

.input-form{
    width: 98%;
    border: 1px solid black;
    height: 34px;
    margin-bottom: 10px;
    outline: none;
}