.events-container{
    width: 100vw;
    height: 100vh;
}

.events-content{
    /* width: 350px; */
    /* height: auto; */
    position: relative;
    /* top: 50%; */
    /* top: 100px; */
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding-bottom: 50px;
}

.events-content h1{
    font-family:"custom-body";
    font-size: 15px;
    margin-bottom: 25px;
}

.event{
    width: 250px;
    height: auto;
    position: relative;
    left:50%;
    transform:translateX(-50%);
    background-color: white;
    padding: 20px;
    padding-top: 35px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    margin-bottom: 50px;
}

.event p{
    font-family:"custom-body";
}

.smaller{
    width: 150px;
    height: 150px;
    margin-top: 10px;
    /* margin-bottom: 25px; */
}

.events-button{
    border: none;
    background-color: transparent;
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 25px;
    margin-top: 10px;
}

.add-event-link{
    margin-top: 20px;
    position: relative;
}