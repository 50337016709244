.success-container{
    width: 100vw;
    height: 100vh;
}

.success-content{
    width: 300px;
    position: relative;
    left: 50%;
    top:50%;
    transform:translate(-50%,-50%);
    text-align: center;
}

.success-content h1{
    font-family:"custom-body";
    /* font-size: 12px; */
}

.success-content a{
    text-align: center;
    text-decoration: none;
}

.success-button{
    font-family:"custom-body";
    color:white;
    font-size:15px;
    text-transform: none;
}