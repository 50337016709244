html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: "helvetica";
	vertical-align: baseline;
  	color:black;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
	background-color: #f6f4ee;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

h1{
  font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

p{
  font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

a{
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

::-webkit-scrollbar {
    display: none;
}

@font-face {
	font-family: "custom";
	src: url("../src/assets/Oswald-SemiBold.ttf");
}

@font-face {
	font-family: "custom-body";
	src: url("../src/assets/Sailec-Medium.ttf");
}

@font-face {
	font-family: "custom-body-light";
	src: url("../src/assets/Sailec-Light.ttf");
}

.login-button{
    background-color:black;
    color:white;
    font-family: "custom-body";
    border-radius: 100px;
	border:none;
    display: block;
	font-size: 15px;
	width: 100%;
    text-align: center;
    margin-bottom: 10px;
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    -webkit-text-decoration: none;
	cursor:pointer;
}

/* @media only screen and (max-width: 767px) {
	body{
		overflow: hidden;
		position: fixed;
	}
  } */

