
.home-container{
    width: 100vw;
    height: 100vh;
}


.home-content-container{
    position: relative;
    top: 50%;
    left:50%;
    transform:translate(-50%, -50%);
    width: 85%;
}

.home-content-container h1{
    font-family: "custom-body-light";
    font-size: 25px;
    text-transform: none;
    text-align: left;
    margin-bottom: 25px;
}

.home-content-links a{
    background-color:black;
    /* color: transparent; */
    color:white;
    font-family: "custom-body";
    border-radius: 100px;
    display: block;
    width: 300px;
    text-align: center;
    margin-bottom: 10px;
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    -webkit-text-decoration: none;
}
