.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .charge-details {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
  }

  .charge-details h3{
    font-family: "custom-body";
    text-align: center;
    margin-bottom: 15px;
    font-size: 18px;
  }

  .charge-details p{
    font-family: "custom-body";
    text-align: center;
    font-size: 13px;
  }

  .charge-details a{
    font-family: "custom-body";
    text-align: center;
    font-size: 13px;
    text-decoration: underline;
  }
  
  .close-button {
    position: relative;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }

  .overlay-margin{
    margin-top: 15px;
  }
  