.auth-code-input {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .auth-code-input input {
    width: 42px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    font-family:"custom-body";
    border: 2px solid black;
    border-radius: 4px;
    background-color: transparent;
  }
  
  .auth-code-input input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .auth-code-input button {
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
  }
  

  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .input-form-snippet{
        margin-right: 1px;
    }

    .auth-code-input input {
      width: 19px;
      height: 50px;

    }

    .auth-code-input {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 9px;
    }

}