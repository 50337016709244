.manage-pass-container{
    width: 100vw;
    height: 100vh;
}

.manage-pass-content{
    width: 350px;
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}

.no-transform{
    text-transform: none;
}

.event-button{
    border: 1px solid black;
    background-color: transparent;
    height: 25px;
    margin-bottom: 25px;
    margin-top: 10px;
}

.manage-pass-content{
    /* display: block;
    position: relative;
    left: 50%;
    top:100px;
    transform:translate(-50%);
    width: 300px; */
    width: 85%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    position: relative;
    /* top: 100px; */
    top: 75px;
    left: 50%;
    transform:translateX(-50%);
    padding-bottom: 100px;
}

.pass{
    /* border: 1px solid black;
    width: 100%;  
    margin-bottom: 10px;
    padding: 10px;
    transition: all .2s ease-in; */
    width: 100%;
    /* height: 50px; */
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    /* padding: 10px; */
    /* padding-top: 100%; create a square */
    /* border-radius: 16px; */
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.manage-events-tagline{
    position: relative;
    top: 100px;
    margin-bottom: 50px;
    text-align: center;
}

.manage-events-tagline h1{
    font-family:'custom-body';
    font-size: 15px;
    text-transform: none;
}

.attendees-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .attendees-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    max-width: 500px;
    width: 80%;
  }
  
  .attendees-box h2 {
    font-size: 18px;
    margin-bottom: 10px;
    font-family:"custom-body";
  }
  
  .attendees-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow-y: scroll;
  }
  
  .attendees-box li {
    font-size: 14px;
    font-family:"custom-body";
    margin-bottom: 10px;
  }

  .no-attendees{
    font-size: 14px;
    font-family:"custom-body";
    margin-bottom: 10px;
    text-align: center;
    text-transform:none;
  }
  
  
  /* .attendees-box button {
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
  } */


  .no-events-message{
    font-family:"custom-body";
    text-align: center;
    font-size: 15px;
    text-transform: none;
    grid-column: 1 / -1; /* This makes the message span all columns in the grid */

  }

  .manage-pass-button{
    width: 75%;
    font-size: 12px;
    font-family:"custom-body";
    position: relative;
    left:50%;
    transform:translateX(-50%);
  }

  .manage-pass-button:nth-child(4){
    margin-top: 20px;
  }

  .delete-confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  
  .delete-confirmation-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .delete-confirmation-box h2 {
    font-size: 18px;
    margin-bottom: 10px;
    font-family:"custom-body";
  }

  .delete-confirmation-box p {
    text-transform: none;
    font-size: 14px;
    font-family:"custom-body";
    margin-bottom: 15px;
  }
  
  

@media screen and (max-width: 768px) {
    .manage-pass-content{
      grid-template-columns: repeat(1, 1fr);
    }
  }