.transactions-container{
    width: 100vw;
    height: 100vh;
}

.center{
    text-align: center;
    margin-bottom: 10px;
}

.transactions-table{
    position: relative;
    display: block;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 85%
}

.transactions-table table{
    width: 100%;
}

.transactions-table table th{
    text-align: left;
}

.transaction-container{
    /* display: block;
    position: relative;
    left: 50%;
    top:100px;
    transform:translate(-50%);
    width: 300px; */
    width: 85%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    position: relative;
    /* top: 100px; */
    top: 75px;
    left: 50%;
    transform:translateX(-50%);
    padding-bottom: 100px;
}

.transaction{
    /* border: 1px solid black;
    width: 100%;  
    margin-bottom: 10px;
    padding: 10px;
    transition: all .2s ease-in; */
    width: 100%;
    height: 50px;
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    /* padding: 10px; */
    /* padding-top: 100%; create a square */
    /* border-radius: 16px; */
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);

}

/* '.transaction:hover{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}' */


.dashboard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
  }
  
  .filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .filter button {
    background-color: #4CAF50;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 20px;
    transition: background-color 0.2s ease;
  }
  
  .filter button:hover {
    background-color: #3e8e41;
  }
  
  .table {
    border-collapse: collapse;
    margin-top: 20px;
    width: 100%;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tfoot td {
    font-weight: bold;
  }

  .summary-view{
    width: 85%;
    height: 50px;
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    margin-bottom:50px;
    /* font-family:"customer-body"; */
    /* padding: 10px; */
    /* padding-top: 100%; create a square */
    /* border-radius: 16px; */
    background-color: black;
    /* color:white; */
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  }

  .summary-view p{
    color:white;
  }

  @media screen and (max-width: 768px) {
    .transaction-container{
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .loader-transactions{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #888;
    animation: loader-spin 1s ease-in-out infinite;
    z-index:999;
  }
  
  @keyframes loader-spin {
    to {
      transform: rotate(360deg);
    }
  }