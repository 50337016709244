.attend-container{
    width: 100vw;
    height: 100vh;
}

.attend-content{
    width: 300px;
    height: 165px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    background-color:white;
    padding: 40px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.attend-content input{
    border: 1px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    padding:0;
    /* border-bottom: 1px solid black; */
    font-size: 25px;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    appearance: none;

}

.attend-button{
    border: 1px solid black;
    width: 100%;
    height: 34px;
    background-color: transparent;

}

.attend-input{
    background-color:transparent;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
}

@media only screen and (max-width: 767px) {
	.attend-content{
        width: 250px;
	}
  }